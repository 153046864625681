<template>
  <div class="main-container">
    <div class="sheet">
      <el-table
          :data="tableData"
          stripe
          ref="confirmTable"
          row-key="instrument_id"
      >
        <el-table-column prop="instrument_id" label="仪器序列号" align="center" width="130"></el-table-column>
        <el-table-column prop="platform_unique" label="仪器型号" align="center" width="180"></el-table-column>
        <el-table-column prop="assets_status" label="资产状态" align="center" width="260">
          <template v-slot="{row}">
<!--            <span v-if="!scope.row.edit">{{ scope.row.assets_status }}</span>-->
            <el-select  v-model=" row.asset_status" placeholder="请选择资产状态" size="small" >
              <el-option
                  v-for="item in assetStatusOptions"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="hospital_name" label="医院" align="center" min-width="270">
          <template v-slot="{row}">
            <el-select
                v-if="row.isHospitalEdit"
                v-model="row.hospital_id"
                placeholder="请输入医院名称"
                size="small"
                filterable
                clearable
                :remote="true"
                :remote-method="hosRemoteMethod"
            >
              <el-option
                  v-for="item in hospitalOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
            <span v-else>{{row.hospital_name}}</span>

          </template>
        </el-table-column>
        <el-table-column prop="hospital_level" label="医院等级" align="center" width="240">
          <template v-slot="{row}">
            <el-select v-model="row.hospital_level" placeholder="请选择医院等级" size="small">
              <el-option
                  v-for="item in hospitalLevelOptions"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="label_id" label="仪器位置" align="center" width="240">
          <template v-slot="{row}">
<!--            <span v-if="!scope.row.edit">{{ scope.row.label }}</span>-->
            <el-select v-model="row.label_id" placeholder="请选择仪器位置" size="small" width="240">
              <el-option
                  v-for="item in locationTagOptions"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="customize_label" label="仪器位置标签" align="center" width="240">
          <template v-slot="{row}">
<!--            <span v-if="!scope.row.edit">{{ scope.row.customize_label }}</span>-->
            <el-input
                v-model="row.customize_label"
                size="small"
                placeholder="请输入内容"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="position_now" label="现存位置" align="center" width="240">
          <template v-slot="{row}">
            <el-select v-model="row.position_now" placeholder="请选择" size="small" @change="changePosition(row)">
              <el-option
                  v-for="item in presentLocationOptions"
                  :key="item.val"
                  :label="item.name"
                  :value="item.val">
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="primary_distributor_id" label="一级经销商" align="center" width="240">
          <template v-slot="{row, $index}">
<!--            <span v-if="!scope.row.edit">{{ scope.row.primary_distributor }}</span>-->
            <el-select
                v-model.number="row.primary_distributor_id"
                placeholder="请输入一级经销商"
                size="small"
                filterable
                clearable
                remote
                :remote-method="(query)=>remoteMethod(query, $index)"
            >
              <el-option
                  v-for="item in organizationList[$index]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="hospital_level" label="终端经销商" align="center"  width="250">
          <template v-slot="{row}">
<!--            <span v-if="!row.edit">{{ row.terminal_distributor }}</span>-->
            <el-input
                v-model="row.terminal_distributor"
                size="small"
                placeholder="请输入内容"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="check_name" label="盘点人员" align="center"></el-table-column>
        <el-table-column prop="check_time" label="盘点时间" align="center" width="180"></el-table-column>
        <el-table-column prop="remarks" label="盘点备注" align="center" width="240">
          <template v-slot="{row}">
            <el-input
                v-model="row.remarks"
                size="small"
                placeholder="请输入内容"
            ></el-input>
          </template>
        </el-table-column>

        <el-table-column prop="attachment_url" label="盘点图片" align="center" width="280">
<!--          attachment_url-->
          <template v-slot="{row,$index}">
            <el-upload
                action="action"
                list-type="picture-card"
                :show-file-list="true"
                :limit="5"
                :multiple="true"
                :file-list="row.fileList"
                :on-preview="handlePictureCardPreview"
                :on-remove="handleRemove"
                :http-request=" (params) => handleUpload(params,$index)"
                :on-success="handleUploadSuccess"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="fileDialogVisible" top="0"  :modal-append-to-body="false"  :append-to-body="true" >
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
          </template>
        </el-table-column>
        <el-table-column prop="check_name" label="是否有更新" align="center">
          <template v-slot="{row}">
            <span>{{row.is_change == 1 ? '是' : '否'}}</span>
          </template>
        </el-table-column>
<!--        <el-table-column prop="cz" label="操作 " width="60" fixed="right">-->
<!--            <template v-slot="{row}">-->
<!--              <div class="caozuo">-->
<!--                <el-tooltip-->
<!--                    popper-class="custom-tooltip-border"-->
<!--                    effect="light"-->
<!--                    placement="left"-->
<!--                    width="15"-->
<!--                    trigger="hover"-->
<!--                    content="修改数据"-->
<!--                    v-if="row.edit == false"-->
<!--                >-->
<!--                  <div-->
<!--                      class="box"-->
<!--                      @click="row.edit = true"-->
<!--                  >-->
<!--                    <img-->
<!--                        src="@/assets/img/marshalone.png"-->
<!--                        alt=""-->
<!--                        @mouseenter="hoverC('marshal', $event)"-->
<!--                        @mouseleave="hoverC('marshalone', $event)"-->
<!--                    />-->
<!--                  </div>-->
<!--                </el-tooltip>-->
<!--                <div-->
<!--                    class="box"-->
<!--                    @click="row.edit = false"-->
<!--                    v-if="row.edit == true"-->
<!--                >-->
<!--                  <img-->
<!--                      src="@/assets/img/wancheng.png"-->
<!--                      alt=""-->
<!--                      @click="saveEditBtn(row)"-->
<!--                  />-->
<!--                </div>-->
<!--              </div>-->
<!--            </template>-->
<!--          </el-table-column>-->

      </el-table>
    </div>
  </div>
</template>
<script >
import { hospitals_list } from '@/api/search.js'
import options from "@/views/AssetManagement/mixin/options";
import {organization_list, search_customer} from "@/api/search";
import {getFileName, put} from "@/assets/js/oss";
import {Message} from "element-ui";
import axios from "axios";
export default {
  mixins: [options],
  props:{
    rowData: {
      type: Array,
      default: []
    }
  },
  data(){
    return{
      action: `${process.env.VUE_APP_BASE_API}/api/upload_ins_file/`,
      options:[],
      locationOptions:[],
      tableData: [],
      selectedRows: [],
      organizationList: [],
      hospitalOptions: [], //医院下拉选择框
      fileDialogVisible: false,
      dialogImageUrl: '',
      token: localStorage.getItem('token') ||localStorage.getItem('vue_token'),
    }
  },
  watch: {},
  created() {
    if(this.rowData){
      this.tableData = JSON.parse(JSON.stringify(this.rowData))
      if(this.tableData.length>0){
        this.tableData.forEach((item,index)=>{
          item.edit = false
          item.primary_distributor_id = item.primary_distributor_id ?  Number(item.primary_distributor_id) : ''
          this.organizationList[index] = [{label:item.primary_distributor, value:item.primary_distributor_id}]
          item.isHospitalEdit = false
          item.position_now_temp = item.position_now
          item.fileList = item.fileList ? item.fileList : []
          item.attachment_url = item.attachments ? item.attachments.map(item=>item.attachment_url) : []
          if(item.attachments.length > 0){
            item.attachments = item.attachments.map(p => {
              return {
                url: p.attachment_url
              }
            });
          }
          item.fileList = item.attachments
        })
      }
    }
  },
  methods:{
    // 医院远程搜索
    hosRemoteMethod(e) {
      this.hospitalOptions = hospitals_list({ hospital_name: e })
    },
    changePosition(row){ //当资产现存位置为经销商时，医院相关信息置空
      if(row.position_now !== 1530){
        row.hospital  = null
        row.hospital_level = null
        row.label_id= null
        row.customize_label = null
        row.hospital_name = null
        row.hospital_id = null
      }else{
        if(row.position_now !== row.position_now_temp){
          row.isHospitalEdit = true
        }else{
          row.isHospitalEdit = false
        }
      }

    },
    async remoteMethod(query, index) {
      let organizationList = search_customer({ customer_name: query })
      setTimeout(()=>{
        this.$set(this.organizationList, index, organizationList)
      },1000)
    },
    // 鼠标放在操作图标时
    saveEditBtn(){},
    hoverC(a, e) {
      e.target.src = require('@/assets/img/' + a + '.png')
    },
    // 上传文件
    handleUpload(file,index) {
      const formData = new FormData();
      formData.append('files', file.file); // 注意：在某些 Element UI 版本中，可能需要使用 file.file 而不是 file.raw
      const config = {
        headers: {
          'Authorization': `Bearer ${this.token}` // 假设 token 已经在 data 或 computed 中定义
        }
      };
      axios.post('http://192.168.0.106:8084/api/upload_asset_file/', formData, config)
          .then(data => {
            // 处理响应
            let resUrl =  data.data.data.urls[0]
              this.tableData[index].fileList.push({url: resUrl, name: file.file.name, uid: file.file.uid})
            //   this.tableData[index].attachment_url.push(url)
            // this.fileList.push({url: preUrl+resUrl, name: file.file.name, uid: file.file.uid})
          })
          .catch(error => {
            // 处理错误
          });
      // this.isSubmit = false
      // this.$emit('getIsSubmit',false)
      // // 获取文件的uid
      // let suffix = getFileName(option.file.name) // 获取文件的后缀名
      // //                          仪器序列号                      仪器型号                返厂类型-维修                                            标号
      // let objName = `/media/report/${option.file.uid}${suffix}`
      // // 调用 ali-oss 中的方法,flieName是存放的文件夹名称，可自己定义
      // put(objName, option.file).then((res) => {
      //   this.isSubmit = true
      //   this.$emit('getIsSubmit',true)
      //   // if (this.nowFileNum === this.uploadFilesLength) {
      //   Message.success('上传成功')
      //   this.nowFileNum = 0
      //   // 注意：为了解决图片闪动问题，必须将uid赋值给图片，否则图片会闪动
      //   this.tableData[index].fileList.push({url: url, name: option.file.name, uid: option.file.uid})
      //   this.tableData[index].attachment_url.push(url)
      //   this.dialogVisible = false
      //   // this.loading.close()
      //   // }
      //   // 上传成功之后，转换真实的地址
      // })
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.fileDialogVisible = true
    },
    handleRemove(file, fileList) {
      this.fileList = fileList
      // this.fileListTemp = fileList.map(item=>item.url)
      this.formData.file_urls = fileList.map(item=>item.url)
    },
    handleUploadSuccess(){},
  }
}
</script>

<style scoped lang="scss">
@import '@/assets/css/asset';
.caozuo img {
  width: 36px;
  height: 36px;
}
::v-deep .el-upload.el-upload--picture-card{
  width: 30px;
  height: 30px;
  .el-icon-plus{
   vertical-align: top;
    font-size: 14px;
    margin-top: 7px;
  }
  .el-upload-list__item-actions{
    width: 30px!important;
    height: 30px!important;
  }
}
::v-deep .el-upload-list--picture-card {
  display: inline-block;
  height: 30px!important;

}
::v-deep .el-upload-list--picture-card .el-upload-list__item{
  width: 30px!important;
  height: 30px!important;
  margin: 0 8px;
}
::v-deep .el-upload-list--picture-card .el-upload-list__item-actions:hover span {
  display: inline-block;
  height: 28px;
  line-height: 28px;
  vertical-align: top;
}

</style>