var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main-container"},[_c('div',{staticClass:"sheet"},[_c('el-table',{ref:"confirmTable",attrs:{"data":_vm.tableData,"stripe":"","row-key":"instrument_id"}},[_c('el-table-column',{attrs:{"prop":"instrument_id","label":"仪器序列号","align":"center","width":"130"}}),_c('el-table-column',{attrs:{"prop":"platform_unique","label":"仪器型号","align":"center","width":"180"}}),_c('el-table-column',{attrs:{"prop":"assets_status","label":"资产状态","align":"center","width":"260"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{attrs:{"placeholder":"请选择资产状态","size":"small"},model:{value:( row.asset_status),callback:function ($$v) {_vm.$set(row, "asset_status", $$v)},expression:" row.asset_status"}},_vm._l((_vm.assetStatusOptions),function(item){return _c('el-option',{key:item.val,attrs:{"label":item.name,"value":item.val}})}),1)]}}])}),_c('el-table-column',{attrs:{"prop":"hospital_name","label":"医院","align":"center","min-width":"270"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.isHospitalEdit)?_c('el-select',{attrs:{"placeholder":"请输入医院名称","size":"small","filterable":"","clearable":"","remote":true,"remote-method":_vm.hosRemoteMethod},model:{value:(row.hospital_id),callback:function ($$v) {_vm.$set(row, "hospital_id", $$v)},expression:"row.hospital_id"}},_vm._l((_vm.hospitalOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1):_c('span',[_vm._v(_vm._s(row.hospital_name))])]}}])}),_c('el-table-column',{attrs:{"prop":"hospital_level","label":"医院等级","align":"center","width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{attrs:{"placeholder":"请选择医院等级","size":"small"},model:{value:(row.hospital_level),callback:function ($$v) {_vm.$set(row, "hospital_level", $$v)},expression:"row.hospital_level"}},_vm._l((_vm.hospitalLevelOptions),function(item){return _c('el-option',{key:item.val,attrs:{"label":item.name,"value":item.val}})}),1)]}}])}),_c('el-table-column',{attrs:{"prop":"label_id","label":"仪器位置","align":"center","width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{attrs:{"placeholder":"请选择仪器位置","size":"small","width":"240"},model:{value:(row.label_id),callback:function ($$v) {_vm.$set(row, "label_id", $$v)},expression:"row.label_id"}},_vm._l((_vm.locationTagOptions),function(item){return _c('el-option',{key:item.val,attrs:{"label":item.name,"value":item.val}})}),1)]}}])}),_c('el-table-column',{attrs:{"prop":"customize_label","label":"仪器位置标签","align":"center","width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"size":"small","placeholder":"请输入内容"},model:{value:(row.customize_label),callback:function ($$v) {_vm.$set(row, "customize_label", $$v)},expression:"row.customize_label"}})]}}])}),_c('el-table-column',{attrs:{"prop":"position_now","label":"现存位置","align":"center","width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-select',{attrs:{"placeholder":"请选择","size":"small"},on:{"change":function($event){return _vm.changePosition(row)}},model:{value:(row.position_now),callback:function ($$v) {_vm.$set(row, "position_now", $$v)},expression:"row.position_now"}},_vm._l((_vm.presentLocationOptions),function(item){return _c('el-option',{key:item.val,attrs:{"label":item.name,"value":item.val}})}),1)]}}])}),_c('el-table-column',{attrs:{"prop":"primary_distributor_id","label":"一级经销商","align":"center","width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-select',{attrs:{"placeholder":"请输入一级经销商","size":"small","filterable":"","clearable":"","remote":"","remote-method":function (query){ return _vm.remoteMethod(query, $index); }},model:{value:(row.primary_distributor_id),callback:function ($$v) {_vm.$set(row, "primary_distributor_id", _vm._n($$v))},expression:"row.primary_distributor_id"}},_vm._l((_vm.organizationList[$index]),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)]}}])}),_c('el-table-column',{attrs:{"prop":"hospital_level","label":"终端经销商","align":"center","width":"250"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"size":"small","placeholder":"请输入内容"},model:{value:(row.terminal_distributor),callback:function ($$v) {_vm.$set(row, "terminal_distributor", $$v)},expression:"row.terminal_distributor"}})]}}])}),_c('el-table-column',{attrs:{"prop":"check_name","label":"盘点人员","align":"center"}}),_c('el-table-column',{attrs:{"prop":"check_time","label":"盘点时间","align":"center","width":"180"}}),_c('el-table-column',{attrs:{"prop":"remarks","label":"盘点备注","align":"center","width":"240"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('el-input',{attrs:{"size":"small","placeholder":"请输入内容"},model:{value:(row.remarks),callback:function ($$v) {_vm.$set(row, "remarks", $$v)},expression:"row.remarks"}})]}}])}),_c('el-table-column',{attrs:{"prop":"attachment_url","label":"盘点图片","align":"center","width":"280"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
var $index = ref.$index;
return [_c('el-upload',{attrs:{"action":"action","list-type":"picture-card","show-file-list":true,"limit":5,"multiple":true,"file-list":row.fileList,"on-preview":_vm.handlePictureCardPreview,"on-remove":_vm.handleRemove,"http-request":function (params) { return _vm.handleUpload(params,$index); },"on-success":_vm.handleUploadSuccess}},[_c('i',{staticClass:"el-icon-plus"})]),_c('el-dialog',{attrs:{"visible":_vm.fileDialogVisible,"top":"0","modal-append-to-body":false,"append-to-body":true},on:{"update:visible":function($event){_vm.fileDialogVisible=$event}}},[_c('img',{attrs:{"width":"100%","src":_vm.dialogImageUrl,"alt":""}})])]}}])}),_c('el-table-column',{attrs:{"prop":"check_name","label":"是否有更新","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',[_vm._v(_vm._s(row.is_change == 1 ? '是' : '否'))])]}}])})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }